export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const BannerContainer = () => import('../../components/BannerContainer/index.vue' /* webpackChunkName: "components/banner-container" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/BaseTextarea/index.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseTitle = () => import('../../components/BaseTitle/index.vue' /* webpackChunkName: "components/base-title" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb/index.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CheckTag = () => import('../../components/CheckTag/index.vue' /* webpackChunkName: "components/check-tag" */).then(c => wrapFunctional(c.default || c))
export const CheckTagGroup = () => import('../../components/CheckTagGroup/index.vue' /* webpackChunkName: "components/check-tag-group" */).then(c => wrapFunctional(c.default || c))
export const ClickCaptcha = () => import('../../components/ClickCaptcha/index.vue' /* webpackChunkName: "components/click-captcha" */).then(c => wrapFunctional(c.default || c))
export const CommonTitle = () => import('../../components/CommonTitle/index.vue' /* webpackChunkName: "components/common-title" */).then(c => wrapFunctional(c.default || c))
export const ContainerPanel = () => import('../../components/ContainerPanel/index.vue' /* webpackChunkName: "components/container-panel" */).then(c => wrapFunctional(c.default || c))
export const ContentContainer = () => import('../../components/ContentContainer/index.vue' /* webpackChunkName: "components/content-container" */).then(c => wrapFunctional(c.default || c))
export const ContentPanel = () => import('../../components/ContentPanel/index.vue' /* webpackChunkName: "components/content-panel" */).then(c => wrapFunctional(c.default || c))
export const CrmGlobalFooter = () => import('../../components/CrmGlobalFooter/index.vue' /* webpackChunkName: "components/crm-global-footer" */).then(c => wrapFunctional(c.default || c))
export const CrmGlobalHeader = () => import('../../components/CrmGlobalHeader/index.vue' /* webpackChunkName: "components/crm-global-header" */).then(c => wrapFunctional(c.default || c))
export const CustomButton = () => import('../../components/CustomButton/index.vue' /* webpackChunkName: "components/custom-button" */).then(c => wrapFunctional(c.default || c))
export const CustomModal = () => import('../../components/CustomModal/index.vue' /* webpackChunkName: "components/custom-modal" */).then(c => wrapFunctional(c.default || c))
export const DescriptionsWithHead = () => import('../../components/DescriptionsWithHead/index.vue' /* webpackChunkName: "components/descriptions-with-head" */).then(c => wrapFunctional(c.default || c))
export const EmptyPage = () => import('../../components/EmptyPage/index.vue' /* webpackChunkName: "components/empty-page" */).then(c => wrapFunctional(c.default || c))
export const Evaluate = () => import('../../components/Evaluate/index.vue' /* webpackChunkName: "components/evaluate" */).then(c => wrapFunctional(c.default || c))
export const GlobalInsureBar = () => import('../../components/GlobalInsureBar/index.vue' /* webpackChunkName: "components/global-insure-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavBar = () => import('../../components/GlobalNavBar/index.vue' /* webpackChunkName: "components/global-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalTopBar = () => import('../../components/GlobalTopBar/index.vue' /* webpackChunkName: "components/global-top-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalUserNav = () => import('../../components/GlobalUserNav/index.vue' /* webpackChunkName: "components/global-user-nav" */).then(c => wrapFunctional(c.default || c))
export const GlobarFooter = () => import('../../components/GlobarFooter/index.vue' /* webpackChunkName: "components/globar-footer" */).then(c => wrapFunctional(c.default || c))
export const LeftMenuBar = () => import('../../components/LeftMenuBar/index.vue' /* webpackChunkName: "components/left-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const LoginWrap = () => import('../../components/LoginWrap/index.vue' /* webpackChunkName: "components/login-wrap" */).then(c => wrapFunctional(c.default || c))
export const PagePanel = () => import('../../components/PagePanel/index.vue' /* webpackChunkName: "components/page-panel" */).then(c => wrapFunctional(c.default || c))
export const ResultPanel = () => import('../../components/ResultPanel/index.vue' /* webpackChunkName: "components/result-panel" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/RichText/index.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const RightSide = () => import('../../components/RightSide/index.vue' /* webpackChunkName: "components/right-side" */).then(c => wrapFunctional(c.default || c))
export const RightSideKefuPhone = () => import('../../components/RightSide/kefuPhone.vue' /* webpackChunkName: "components/right-side-kefu-phone" */).then(c => wrapFunctional(c.default || c))
export const SideMenuContainer = () => import('../../components/SideMenuContainer/index.vue' /* webpackChunkName: "components/side-menu-container" */).then(c => wrapFunctional(c.default || c))
export const SliderCaptchaPanel = () => import('../../components/SliderCaptcha/SliderCaptchaPanel.vue' /* webpackChunkName: "components/slider-captcha-panel" */).then(c => wrapFunctional(c.default || c))
export const SliderCaptchaCore = () => import('../../components/SliderCaptcha/core.js' /* webpackChunkName: "components/slider-captcha-core" */).then(c => wrapFunctional(c.default || c))
export const SliderCaptcha = () => import('../../components/SliderCaptcha/index.vue' /* webpackChunkName: "components/slider-captcha" */).then(c => wrapFunctional(c.default || c))
export const Status = () => import('../../components/Status/index.vue' /* webpackChunkName: "components/status" */).then(c => wrapFunctional(c.default || c))
export const TabsIn = () => import('../../components/TabsInModal/TabsIn.vue' /* webpackChunkName: "components/tabs-in" */).then(c => wrapFunctional(c.default || c))
export const TabsInModal = () => import('../../components/TabsInModal/index.vue' /* webpackChunkName: "components/tabs-in-modal" */).then(c => wrapFunctional(c.default || c))
export const TipModal = () => import('../../components/TipModal/index.vue' /* webpackChunkName: "components/tip-modal" */).then(c => wrapFunctional(c.default || c))
export const CrmGlobalHeaderLeftBar = () => import('../../components/CrmGlobalHeader/LeftBar/index.vue' /* webpackChunkName: "components/crm-global-header-left-bar" */).then(c => wrapFunctional(c.default || c))
export const CrmGlobalHeaderRightBar = () => import('../../components/CrmGlobalHeader/RightBar/index.vue' /* webpackChunkName: "components/crm-global-header-right-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalInsureBarLeft = () => import('../../components/GlobalInsureBar/Left/index.vue' /* webpackChunkName: "components/global-insure-bar-left" */).then(c => wrapFunctional(c.default || c))
export const GlobalInsureBarRight = () => import('../../components/GlobalInsureBar/Right/index.vue' /* webpackChunkName: "components/global-insure-bar-right" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavBarLeftPanel = () => import('../../components/GlobalNavBar/LeftPanel/index.vue' /* webpackChunkName: "components/global-nav-bar-left-panel" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavBarSearchPanel = () => import('../../components/GlobalNavBar/SearchPanel/index.vue' /* webpackChunkName: "components/global-nav-bar-search-panel" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavBarTabBar = () => import('../../components/GlobalNavBar/TabBar/index.vue' /* webpackChunkName: "components/global-nav-bar-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavBarTabPanel = () => import('../../components/GlobalNavBar/TabPanel/index.vue' /* webpackChunkName: "components/global-nav-bar-tab-panel" */).then(c => wrapFunctional(c.default || c))
export const GlobalTopBarLeftBar = () => import('../../components/GlobalTopBar/LeftBar/index.vue' /* webpackChunkName: "components/global-top-bar-left-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalTopBarRightBar = () => import('../../components/GlobalTopBar/RightBar/index.vue' /* webpackChunkName: "components/global-top-bar-right-bar" */).then(c => wrapFunctional(c.default || c))
export const GlobalUserNavLeftPanel = () => import('../../components/GlobalUserNav/LeftPanel/index.vue' /* webpackChunkName: "components/global-user-nav-left-panel" */).then(c => wrapFunctional(c.default || c))
export const GlobalUserNavSearchPanel = () => import('../../components/GlobalUserNav/SearchPanel/index.vue' /* webpackChunkName: "components/global-user-nav-search-panel" */).then(c => wrapFunctional(c.default || c))
export const GlobalUserNavTabPanel = () => import('../../components/GlobalUserNav/TabPanel/index.vue' /* webpackChunkName: "components/global-user-nav-tab-panel" */).then(c => wrapFunctional(c.default || c))
export const LoginWrapFooter = () => import('../../components/LoginWrap/Footer/index.vue' /* webpackChunkName: "components/login-wrap-footer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
